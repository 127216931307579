import {post} from '@/requests'

export function login(name, password) {
    let params = {}
    params.name = name
    params.password = password
    return post("auth/login", params)
}


export function register(params) {
    return post("auth/register", params)
}
