<template style="background-color: #ebedf0">
  <van-nav-bar title="注册">

  </van-nav-bar>


<div style="text-align: center">
  <van-image width="11rem" height="11rem" :src="logoImg"/>
</div>
  <van-form @submit="onSubmit" style="margin-top: 1rem">
    <van-cell-group inset>
      <van-field
          v-model="name"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          autocomplete="off"
          :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
          v-model="code"
          name="邀请码"
          label="邀请码"
          placeholder=""
          autocomplete="off"
          :rules="[{ required: true, message: '请填写邀请码' }]"
      />
      <van-field
          v-model="phone"
          name="手机号码"
          label="手机号码"
          placeholder="手机号码"
          autocomplete="off"
          :rules="[{ required: true, message: '请填手机号码' },
           { len: 11,message: '格式不正确', trigger: 'blur',},]"
      />
      <van-field
          v-model="password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
          autocomplete="off"
          :rules="[
              { required: true, message: '请填写密码' },
              { min: 6,max: 16,message: '长度在 6 到 16 个字符', trigger: 'blur',},
              ]"
      />
      <van-field
          v-model="password_c"
          type="password"
          name="确认密码"
          label="确认密码"
          placeholder="确认密码"
          autocomplete="off"
          :rules="[
              { required: true, message: '请填写密码' },
              { validator: confirmPws, trigger: 'blur', message: '密码不一致' },
          ]"
      />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button block type="primary" native-type="submit" loading-type="spinner" :loading="loading">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import {register} from '@/http/user'
import {saveAuth} from '@/services/auth'
import {Toast} from 'vant'
import logoImg from "../assets/video.png"


export default {
  name: "Register",
  data() {
    return {
      name: "",
      code: "",
      password: "",
      phone: "",
      password_c: "",
      loading: false,
      logoImg: logoImg
    }
  },
  methods: {
    confirmPws(){
      return this.password === this.password_c
    },
    onSubmit() {
      this.loading = !this.loading
      let form = {}
      form.name = this.name
      form.password = this.password
      form.phone = this.phone
      form.code = this.code
      register(form).then(function (response) {
        if (response.data.token) {
          let token = response.data.token
          //写入鉴权
          saveAuth(token)
          //跳转页面
          Toast.success(response.msg)
          this.$router.push('/')
        } else {
          Toast.fail(response.msg)
        }
        this.loading = !this.loading
      }.bind(this))
    }
  }
}
</script>

<style scoped>

</style>